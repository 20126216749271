import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const StyledSidebar = styled.div`
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 1.1rem;
  letter-spacing: 0.075rem;
  /* font-weight: 500; */
  width: 10rem;
  min-width: 4rem;
  height: 100%;
  > div {
    margin-bottom: 1rem;
  }
`;

export default function SideBar() {
  return (
    <StyledSidebar>
      <div>
        {/* <NavLink
          to="/desigsn"
          className="breadcrumb"
          activeClassName="selected"
        >
          product manager
        </NavLink> */}
        {/* <a href="/design" className="breadcrumb">design</a> */}
      </div>
      <div>
        {/* <NavLink to="/design" className="breadcrumb" activeClassName="selected">
          designer
        </NavLink> */}
        {/* <a href="/design" className="breadcrumb">design</a> */}
      </div>
      <div>
        <NavLink
          to="/writing"
          className="breadcrumb"
          activeClassName="selected"
        >
          writing
        </NavLink>
        {/* <a href="/writing" className="breadcrumb">writing</a> */}
      </div>
    </StyledSidebar>
  );
}
