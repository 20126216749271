import React, { useEffect, useState } from "react";
import styled from "styled-components";

import TopBar from "components/TopBar/TopBar";
import SideBar from "components/SideBar/SideBar";
import MobileMenu from "components/MobileMenu/MobileMenu";

const StyledPage = styled.div`
  position: relative;
`;
const Canvas = styled.div`
  display: flex;
  width: 100%;

  /* background-color: red; */
`;
export default function Page(props) {
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const Content = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    margin-right: ${width >= 960 ? "4rem" : "default"};
    p > img {
      width: 100%;
      /* margin-left: -10%; */
    }
  `;

  return (
    <StyledPage>
      {showMobileMenu && (
        <MobileMenu showMobileMenuFunction={setShowMobileMenu} />
      )}
      <TopBar
        currentPage={props.currentPage.toLowerCase()}
        mobileLayout={width < 960}
        showMobileMenuFunction={setShowMobileMenu}
      />
      <Canvas>
        {width >= 960 && <SideBar />}
        <Content>{props.children}</Content>
      </Canvas>
    </StyledPage>
  );
}
