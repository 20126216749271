import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Button } from "@mantine/core/";

const StyledTopBar = styled.div`
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 1.1rem;
  letter-spacing: 0.075rem;
  font-weight: 600;

  display: flex;
  margin-bottom: 1rem;
  > Button {
    margin-right: 1rem;
  }
`;
const ButtonContainer = styled.div`
  margin-right: 1rem;
`;
const BreadCrumbWrapper = styled.div`
  /* line-height: 10px; */
`;

function createBreadcrumb(currentPage, mobileLayout) {
  let urlComponents = window.location.href.split("/");
  if (urlComponents[3] === "") {
    //root
    return (
      <>
        <span className="selected">sjur sundin</span>
      </>
    );
  }
  if (urlComponents.length === 4) {
    return (
      <>
        <a href="/" className="breadcrumb">
          sjur sundin
        </a>{" "}
        / {urlComponents[3]}
      </>
    );
  }
  if (urlComponents.length === 5) {
    if (mobileLayout) {
      return (
        <>
          <a href="/" className="breadcrumb">
            sjur sundin
          </a>{" "}
          / {currentPage}{" "}
        </>
      );
    }
    return (
      <>
        <a href="/" className="breadcrumb">
          sjur sundin
        </a>{" "}
        /{" "}
        <NavLink to={"/" + urlComponents[3]} className="breadcrumb">
          {urlComponents[3]}
        </NavLink>{" "}
        / {currentPage}
      </>
    );
  }
}

export default function TopBar(props) {
  var breadCrumb = createBreadcrumb(props.currentPage, props.mobileLayout);
  return (
    <StyledTopBar>
      {props.mobileLayout && (
        <ButtonContainer>
          <Button
            variant="outline"
            color="dark"
            size="xs"
            radius="xs"
            onClick={() => props.showMobileMenuFunction(true)}
          >
            menu
          </Button>
        </ButtonContainer>
      )}
      <BreadCrumbWrapper>{breadCrumb}</BreadCrumbWrapper>
    </StyledTopBar>
  );
}
