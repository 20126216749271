import React from "react";
import styled from "styled-components";

export default function BlogPageFooter() {
  return (
    <p>
      <hr />I hope you found this helpful. Please feel free to reach out on{" "}
      <a href="https://www.linkedin.com/in/sjurs/">LinkedIn</a> if you have
      questions, comments, or just want to chat about design.
    </p>
  );
}
