import "./App.css";
import ReactGA from "react-ga";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "pages/home/Home";
import Design from "pages/design/Design";
import Ibm from "pages/design/ibm/Ibm";
import Writing from "pages/writing/Writing";
import {
  W21072001,
  W21072002,
  W21072003,
  W21072004,
  W21072005,
  W21072006,
} from "content/writing/Articles";

function App() {
  const TRACKING_ID = "UA-203329284-1";
  ReactGA.initialize(TRACKING_ID);
  return (
    <BrowserRouter basename="/">
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/design" component={Design} exact />
        <Route path="/design/ibm" component={Ibm} exact />
        {/* writing */}
        <Route
          path="/writing"
          component={Writing}
          exact
          activeClassName="selected"
        />
        <Route
          path="/writing/changing-your-career-to-UX-design"
          exact
          component={W21072001}
        />
        <Route
          path="/writing/are-you-too-old-to-become-a-designer"
          exact
          component={W21072002}
        />
        <Route
          path="/writing/sales-is-your-friend"
          exact
          component={W21072003}
        />
        <Route
          path="/writing/what-to-look-for-in-a-design-internship"
          exact
          component={W21072004}
        />
        <Route
          path="/writing/design-and-ship-large-features-faster-part-1-of-2"
          exact
          component={W21072005}
        />
        <Route
          path="/writing/design-and-ship-large-features-faster-part-2-of-2"
          exact
          component={W21072006}
        />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
