import React from "react";
import styled from "styled-components";
import Tag from "components/Tag/Tag";

const StyledCard = styled.div`
  height: auto;
  border-top: 1px solid black;
  padding-top: 0.5rem;
  @media (min-width: 960px) {
    :hover {
      cursor: pointer;
      > h3 {
        color: red;
      }
    }
  }
  > h3 {
    margin-top: 0.5rem;
  }
`;
const CardImageContainer = styled.div`
  > img {
    width: 100%;
  }
`;
export default function Card(props) {
  return (
    <StyledCard>
      {props.tag ? <Tag>{props.tag}</Tag> : <Tag>other</Tag>}
      {props.date ? <Tag>{props.date}</Tag> : null}
      <h3>{props.title ? props.title : "Dummy title"}</h3>
      {props.img && (
        <CardImageContainer>
          <img
            alt="cover art"
            src="https://images.squarespace-cdn.com/content/v1/54691359e4b027698c273fe0/1531267287648-UIH3VX4JRRR8JTI1JFT3/1280+%281%29.png?format=2500w"
          />
        </CardImageContainer>
      )}
      <p>{props.children ? props.children : "Lorem ipsum"}</p>
    </StyledCard>
  );
}
