import React from "react";
import Page from "components/Page/Page";
import { Grid, Row, Col } from "components/Grid/Grid";

function Writing() {
  return (
    <Page currentPage="sjur sundin">
      <Grid width="80%">
        <Row>
          <Col size={1}>
            <h3>Hi, I'm Sjur</h3>
            <h3>
              My portfolio has moved to:{" "}
              <a
                href="https://sjur.notion.site/Sjur-Sundin-db5d5df6de2b4189876a30bce29d13e4?pvs=74"
                target="_blank"
                rel="noreferrer"
              >
                <b>sjur.notion.site</b>
              </a>
            </h3>
            <p>
              You can read my <a href="/writing">writing</a>, find me on{" "}
              <a
                href="https://www.linkedin.com/in/sjurs/"
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </a>
              , see my{" "}
              <a
                href="https://drive.google.com/file/d/1mNLDxb2AxM2VYXBL8aGaQK5QSfS2SYfJ/view"
                target="_blank"
              >
                resume
              </a>
              .
            </p>
          </Col>
        </Row>
      </Grid>
    </Page>
  );
}
export default Writing;
