import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Grid, Row, Col, TextCol } from 'components/Grid/Grid'
import Page from 'components/Page/Page'
import BlogPageFooter from './BlogPageFooter';

export default function BlogPage(props) {
    const [article, setArticle] = useState('');
    let urlString = window.location.origin + '/content/' + props.markdownFile;
    const getData = () => {
        fetch(urlString).then(response => response.text())
            .then(result => setArticle(result));
    }
    useEffect(() => {
        getData()
    })
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    return (
        <Page currentPage={props.currentPage.toLowerCase()}>
            <Grid width="100%">
                <Row>
                    {(width >= 960) ? <><Col size={1}></Col>
                        <TextCol>
                            <ReactMarkdown children={article} />
                        <BlogPageFooter />
                        </TextCol><Col size={1}></Col></> : <Col size={1}>
                        <ReactMarkdown children={article} />
                        <BlogPageFooter />
                    </Col>}
                </Row>
            </Grid>
        </Page>

    )
}