import styled from "styled-components";

export const Grid = styled.div`
  width: ${(props) => props.width};
  min-width: ${(props) => props.width};
`;
export const Row = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
  > :first-child {
    padding-left: 0;
  }
  > :last-child {
    padding-right: 0;
  }
`;
export const Col = styled.div`
  flex: ${(props) => props.size};
  padding: 0 1rem;
`;
export const TextCol = styled.div`
  min-width: 640px;
  max-width: 640px;
`;
