import React from 'react'
import styled from 'styled-components'

const StyledTag = styled.div`
    display: inline-block;
    font-size: 0.65rem;
    line-height: 0.5rem;
    text-transform: uppercase;
    font-weight: 500;
    :hover {
        /* cursor: pointer; */
        /* border: 1px solid red; */
        /* color: red; */

    }
`
export default function Tag(props) {
    return (
        <StyledTag>
            {props.children}
        </StyledTag>
    )
}