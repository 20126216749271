import React from "react";
import styled from "styled-components";
import { Button } from "@mantine/core";
import { NavLink } from "react-router-dom";

const StyledMobileMenu = styled.div`
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 1.1rem;
  letter-spacing: 0.075rem;
  position: absolute;
  top: -1rem;
  right: -1rem;
  bottom: -1rem;
  left: -1rem;
  padding: 1rem;
  background-color: #fafafa;
  > Button {
    display: block;
    margin-top: 3px;
    margin-bottom: 1rem;
  }
  > div {
    margin-bottom: 1rem;
  }
`;

export default function MobileMenu(props) {
  return (
    <StyledMobileMenu>
      <Button
        variant="outline"
        color="dark"
        size="xs"
        radius="xs"
        onClick={(event) => {
          event.stopPropagation();
          props.showMobileMenuFunction(false);
        }}
      >
        close
      </Button>
      <div>
        <NavLink
          to="/"
          className="breadcrumb"
          onClick={(event) => {
            event.stopPropagation();
            props.showMobileMenuFunction(false);
          }}
        >
          <span className="selected">sjur sundin</span>
        </NavLink>
      </div>
      <div>
        <NavLink
          to="/design"
          className="breadcrumb"
          onClick={(event) => {
            event.stopPropagation();
            props.showMobileMenuFunction(false);
          }}
        >
          design
        </NavLink>
      </div>
      <div>
        <NavLink
          to="/writing"
          className="breadcrumb"
          onClick={(event) => {
            event.stopPropagation();
            props.showMobileMenuFunction(false);
          }}
        >
          writing
        </NavLink>
      </div>
    </StyledMobileMenu>
  );
}
