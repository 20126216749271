//all articles
import React from "react";
import BlogPage from "components/Page/BlogPage";

export function W21072001() {
  return (
    <BlogPage
      markdownFile="w21072001.md"
      currentPage="Changing your career to UX design if you're not a designer"
    />
  );
}
export function W21072002() {
  return (
    <BlogPage
      markdownFile="w21072002.md"
      currentPage="Are you too old to become a designer"
    />
  );
}
export function W21072003() {
  return (
    <BlogPage markdownFile="w21072003.md" currentPage="Sales is your friend" />
  );
}
export function W21072004() {
  return (
    <BlogPage
      markdownFile="w21072004.md"
      currentPage="What to look for in a design internship"
    />
  );
}
export function W21072005() {
  return (
    <BlogPage
      markdownFile="w21072005.md"
      currentPage="Design and ship large features faster, part 1/2"
    />
  );
}
export function W21072006() {
  return (
    <BlogPage
      markdownFile="w21072006.md"
      currentPage="Design and ship large features faster, part 2/2"
    />
  );
}
