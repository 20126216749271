import React, { useState, useEffect } from "react";
import Page from "components/Page/Page";
import Card from "components/Card/Card";
import { Grid, Row, Col } from "components/Grid/Grid";
import { NavLink } from "react-router-dom";

const articles = [
  <NavLink to="/writing/changing-your-career-to-UX-design">
    <Card
      title="Changing your career to UX design if you're not a designer"
      tag="27 jul 2021"
    >
      Are you considering changing careers and becoming a designer? Perhaps
      you're wondering which, if any, of your skills are transferable, and how
      much effort you have to put in to make the change?
    </Card>
  </NavLink>,
  <NavLink to="/writing/are-you-too-old-to-become-a-designer">
    <Card title="Are you too old to become a designer?" tag="10 aug 2021">
      No. Seriously, no. Unless...
    </Card>
  </NavLink>,
  <NavLink to="/writing/sales-is-your-friend">
    <Card title="Sales is your friend" tag="26 aug 2021">
      Get to know the sales people in your company. They probably spend much
      more time talking to users than you do.{" "}
    </Card>
  </NavLink>,
  <NavLink to="/writing/what-to-look-for-in-a-design-internship">
    <Card title="What to look for in a design internship" tag="Sep 08 2021">
      When looking for an internship you want to come out of it with more
      experience and as a better designer than when you started it.
    </Card>
  </NavLink>,
  <NavLink to="/writing/design-and-ship-large-features-faster-part-1-of-2">
    <Card
      title="Design and ship large features faster, part 1/2"
      tag="Oct 02 2021"
    >
      Overwhelmed when designing large features? How can you ship it faster and
      deliver value to users early?
    </Card>
  </NavLink>,
  <NavLink to="/writing/design-and-ship-large-features-faster-part-2-of-2">
    <Card
      title="Design and ship large features faster, part 2/2"
      tag="Oct 30 2021"
    >
      Overwhelmed when designing large features? Read part 2 in my series on how
      to deliver value early.
    </Card>
  </NavLink>,
];

function Writing() {
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <Page currentPage="writing">
      {width >= 960 ? (
        <Grid width="80%">
          <Row>
            <Col size={1}>{articles[4]}</Col>
            <Col size={1}>{articles[5]}</Col>
            <Col size={1}>{articles[0]}</Col>
          </Row>
          <Row>
            <Col size={1}>{articles[3]}</Col>
            <Col size={1}>{articles[2]}</Col>
            <Col size={1}>{articles[1]}</Col>
          </Row>
        </Grid>
      ) : (
        <Grid width="80%">
          <Row>
            <Col size={1}>
              {articles[4]}
              {articles[5]}
              {articles[0]}
              {articles[3]}
              {articles[2]}
              {articles[1]}
            </Col>
          </Row>
        </Grid>
      )}
    </Page>
  );
}
export default Writing;
