import React from "react";
import Page from "components/Page/Page";
// import Card from 'components/Card/Card'
import { Grid, Row, Col } from "components/Grid/Grid";

function Writing() {
  return (
    <Page currentPage="design">
      <Grid width="80%">
        <Row>
          {/* <Col size={1}>

                    </Col> */}

          <Col size={2}>
            {/* <Card title="
                        Interpreta healthcare analytics platform" tag="design / interpreta" img="https://images.squarespace-cdn.com/content/v1/54691359e4b027698c273fe0/1531267287648-UIH3VX4JRRR8JTI1JFT3/1280+%281%29.png">
                            Interpreta healthcare analytics is a SaaS care orchestration platform enabling physicians, care managers, clinic administrators and insurance companies to manage their patient population based on multiple quality metrics and risk factors. It also gives actionable insight into risk factors and quality of care for individual patients.
                        </Card> */}
            Coming soon, portfolio of my design work
          </Col>
          <Col size={1}></Col>
        </Row>
      </Grid>
    </Page>
  );
}
export default Writing;
